import React from 'react';
import './window/window.css';
import me from '../files/me.JPG';
import nail from '../files/icons/nail.png'; 

const skills = [
  "Python",
  "C/C++",
  "C#",
  "Java",
  "Kotlin",
  "Git",
  "JavaScript",
  "HTML/CSS",
  "SQL",
  "Matlab & R",
  "React",
  "ASP.NET",
  "PHP",
  "Node.js",
  "Figma",
  "Docker"
];

const colors = [
  "#4D89E2", "#83A468", "#AA87A6", "#83BEE0", "#B75D5D",
  "#D2ABDB", "#DED76A", "#E89CC1", "#E58551",
  "#F4A59A"
];

function Aboutme() {
    return (
        <div className='Indent-Content'>
          <img src={me} alt="Morgan Burlin" className="Me-Picture" />
          <h1 className="h1-Big">Morgan Burlin</h1>
          <h2 className="h2-light">
            <img src={nail} alt="Place marker icon" className="Text-Icon" />
            Umeå, Sweden
          </h2>
          <div className="container">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="container-div"
                style={{ backgroundColor: colors[index % colors.length] }}
              >
                {skill}
              </div>
            ))}
          </div>
          <h2 className="h2-bold">
          22-year-old Master of Science in Engineering student at Umeå University, Sweden. I mainly focus on Interaction Technology and Design, web development and Full-stack.
          </h2>
          <h2 className="h2-normal">
            I´m an <strong>energetic</strong> and <strong>social</strong> person who thrives in both collaborative and independent work environments. With a goal-oriented and time-efficient mindset, I always aim to meet deadlines while maintaining a focus on delivering outstanding results, often bringing creative solutions along the way.
            <br></br>
            Passionate about personal development, I am always eager to tackle new challenges, learn quickly, and adapt to changing situations.
            </h2>
          <h2 className="h2-normal">
          Besides this, I am someone who always has something going on—whether it’s brewing my own beer at my parents’ summer cottage or learning how to surf.
          I love traveling and exploring new places, being in nature, hiking, running, fishing, hunting, and camping and I hope to keep trying new things and discovering new places for the rest of my life :)
          </h2>
          
        </div>
    );
}

export default Aboutme;
